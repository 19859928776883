// Add here all your customized variables

//
// Typography
//

// Font Families
$g-font-primary: "Lato", sans-serif;

//
// Colors
//

// Main Colors
$g-color-main: #38528C;

// Primary Color
$g-color-primary: #2CADF2;

//
// Status Colors

// Color Success
$g-color-success:             #2CADF2;

// Color Info
$g-color-info:                #2CADF2;
