/* Add here all your css styles (customizations) */

// Variables
@import "custom.variables";

// Mixins
@import "custom.mixins";


@import "../tools/mixins/gradients";
/* White Gradient */
.g-bg-white-gradient-opacity-v1,
.g-bg-white-gradient-opacity-v1--after::after {
  @include gradient-y-top($start-color: rgba(#000, .8), $end-color: rgba(#000, 0.2));
}

/* Undoes hover styling on touch devices - carousel arrows */
@media (hover: none) {
  .g-color-primary:hover {
    color: $g-color-primary !important;
  }
  .g-bg-primary-opacity-0_1:hover {
    background-color: rgba($g-color-primary, .1) !important;
  }
}

/* Add active styling to carousel arrows - must be after the undo hover styling above */
.g-bg-primary--hover:active {
  background-color: darken($g-color-primary, 10%) !important;
}
.g-color-white--hover:active {
  color: #fff !important;
}
